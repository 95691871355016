<template>
  <div>
    <LazyAppMasquerade v-if="isMasquerading" />
    <AppHeader />
    <slot />
    <div v-if="hasHostFooter">
      <ZContainer class="mb-5">
        <ContentFeaturedHostBlog />
      </ZContainer>
      <AppFooter is-host-variant />
    </div>
    <AppFooter v-else />
  </div>
</template>

<script setup lang="ts">
/**
 * This layout is for dashboard pages
 * It can probably be deleted and replaced with the default layout
 */

usePartytown()

const { isMasquerading } = useMasquerade()

useHead({
  bodyAttrs: {
    class: 'dashboard',
  },
})

const hasHostFooter = computed(() => {
  // TODO
  return (
    // this.$auth?.user.IsOwner && (this.$route.name === 'dashboard___en' || this.$route.name === 'dashboard___fr')
    false
  )
})
</script>

<style lang="scss">
.dashboard {
  color: getColor('primary-400');

  h1 {
    @include title-1;
    @include bold;

    color: getColor('primary-500');
    margin-bottom: 1rem;

    @include media-min-size(medium) {
      @include title-3;
      @include bold;

      margin-bottom: 1.5rem;
    }
  }

  h3 {
    @include subtitle-1;

    color: getColor('primary-500');
    margin-bottom: 1rem;
  }

  hr {
    margin: 1.5rem 0;
    border-top-color: getColor('highlight-100');
  }
}
</style>
